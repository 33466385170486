// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Ricardo III",
  middleName: "",
  lastName: "Ferrancullo",
  message: " Passionate about changing the world with technology. ",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/RicTheThird",
    },
    {
      image: "fa-facebook",
      url: "https://www.facebook.com/thet.ferrancullo",
    },
    {
      image: "fa-instagram",
      url: "https://www.instagram.com/ric.thethird/",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/ricardoferrancullo/",
    },
    // {
    //   image: "fa-twitter",
    //   url: "https://www.twitter.com/FerranculloRic",
    // },    
    {
      image: "fa-stack-overflow",
      url: "https://stackoverflow.com/users/5022318/ricardo-d-ferrancullo-iii?tab=profile",
    },
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/hashirshoaeb.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/ricthethird.jpg"),
  imageSize: 375,
  message:
    "Ricardo Ferrancullo III \"a.k.a Ric\" is currently a Senior Software Engineer at Woolworths New Zealand. Ric obtained his degree at \
    STI College Lipa in the Philippines with a degree of B.Sc. in Information Technology.  During his academic year, he stand out by \
    competing and winning in various programming competition held by STI College nationwide and awarded as Programmer of the year in 2015. \
    His major milestone in career started when IBM Philippines offered him a role as Application Developer and eventually moved to Kuala Lumpur, \
    Malaysia as Java Developer for Maybank then again had an opportunity to work and reside in New Zealand. \
    His passion within the field of software engineering gained him an extensive experience on his career and trying to give back by contributing to open source community.",
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  heading: "Recent Projects",
  gitHubUsername: "RicTheThird", //i.e."johnDoe12Gh"
  reposLength: 4,
  specificRepos: [],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
  images: [
    
  ],
  imageSize: {
    width:"615",
    height:"450"
  }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Skills",
  hardSkills: [
    { name: "C#", value: 90 },
    { name: "SQL", value: 85 },
    { name: "Data Structures", value: 90 },
    { name: "Java", value: 75 },
    { name: "JavaScript", value: 90 },
    { name: "React", value: 90 },
    { name: "HTML/CSS", value: 95 },
    { name: "Angular", value: 80 },
    { name: "Automation Testing", value: 80 },
    { name: "Azure Services", value: 85 },
  ],
  softSkills: [
    { name: "Goal-Oriented", value: 80 },
    { name: "Collaboration", value: 90 },
    { name: "Positivity", value: 75 },
    { name: "Adaptability", value: 85 },
    { name: "Problem Solving", value: 75 },
    { name: "Empathy", value: 90 },
    { name: "Organization", value: 70 },
    { name: "Creativity", value: 90 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
    "If you want a personalized website like this or you just wanna say hi, you can email me at",
  email: "ricthethird20@gmail.com",
};

const experiences = {
  show: true,
  heading: "Experiences",
  data: [
    {
      role: 'Senior Software Developer',// Here Add Company Name
      companylogo: require('../assets/img/woolworths_nz.jpg'),
      date: 'May 2022 – Present',
    },
    {
      role: 'Software Engineer',// Here Add Company Name
      companylogo: require('../assets/img/lps.jpg'),
      date: 'March 2019 – May 2022',
    },
    {
      role: 'Full Stack Developer',
      companylogo: require('../assets/img/maybank.jpg'),
      date: 'December 2016 – December 2018',
    },
    {
      role: 'Application Developer',// Here Add Company Name
      companylogo: require('../assets/img/ibm.jpg'),
      date: 'June 2016 – December 2016',
    },
    {
      role: 'R & D Software Engineer',
      companylogo: require('../assets/img/kinpo.jpg'),
      date: 'June 2015 – June 2016',
    },
  ]
}

const achievements = {
  show: true,
  heading: "Achievements",
  data: [
    {
      role: '',// Here Add Company Name
      companylogo: require('../assets/img/azure-devops.png'),
      date: '',
      link: 'https://www.credly.com/badges/61a65662-a05e-4f5e-b7b4-97aa83740ec9'
    },
    {
      role: '',// Here Add Company Name
      companylogo: require('../assets/img/azure-developer-associate.png'),
      date: '',
      link: 'https://www.credly.com/badges/f7987772-6520-487f-8f7b-36b5b88cc2a1'
    },
    {
      role: '',// Here Add Company Name
      companylogo: require('../assets/img/azure-fundamentals.png'),
      date: '',
      link: 'https://www.credly.com/badges/af4454e9-40ed-42d8-8b02-fe146cb5f58a'
    }
  ]
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences, achievements };
